import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import {motion} from "framer-motion"

export default function RightHome({ elementsContent, highlightElement, activeElement}) {
  const { darkTheme } = useContext(UserContext);
  return (
    <div className={`rightMenu scrollBar hidden lg:block overflow-auto`}>
      <div className="font-medium md:text-lg pb-4">On this Page</div>
      {elementsContent?.length>0 &&(
        <motion.div
        key={elementsContent}
        initial={{opacity: 0, y:25}}
        animate={{opacity:1, y:0}}
        exit={{opacity: 1, y: 25}}
        transition={{delay: 0.30, type: "easeInOut", duration:0.7}}
        >
        {
          elementsContent.map((tag,tagIndex)=>(
            <div key={tagIndex}
            onClick={() => {
              // Scroll to the tag when clicked in the sidebar
              const target = document.getElementById(`tag-${tagIndex}`);
              target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
              highlightElement(target); // Highlight the corresponding tag
            }}
            className={`py-1 px-2 cursor-pointer line-clamp-1 ${activeElement === `tag-${tagIndex}` ? 'active' : ''}`}
            >
              {tag.textContent}
            </div>
          ))
        }
        </motion.div>
      )} 
      
    </div>
  );
}
