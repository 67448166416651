import { useContext, useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { UserContext } from "./components/UserContext";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import Header from "./components/pages/Header";
import { ProtectedRoute } from "./components/pages/ProtectedRoute";
import { Callback } from "./components/Oauth2/Callback";
import Login from "./components/pages/Login";
import ProtectedPage from "./components/pages/ProtectedPage";
import PageNotFound from "./components/pages/PageNotFound";
import Layout from "./components/reusable/Layout";
import Home from "./components/pages/Home";
import { BaseURL } from "./components/BaseURL";
import toast from "react-hot-toast";
import { AccessToken } from "./components/reusable/AccessToken";

const GlobalStyle = createGlobalStyle`
body{
  background: ${({ darkTheme }) => (darkTheme ? "#212121" : "#eeeeee")};
  color: ${(props) => (props.darkTheme ? "#fffd" : "black")};
}
`;

function App() {
  const {
    darkTheme,
    setIsSearchOpen,
    allPosts,
    setAllPosts,
    setBodyData,
    selectedHighlight,
    setSelectedHighlight,
  } = useContext(UserContext);
  // const accessToken = localStorage.getItem("access_token");
  const [postLoader, setPostLoader] = useState(false);

  const url = window.location.href;
  const endPath = url.split("/").pop();

  const getAllPostFunc = async () => {
    setPostLoader(true);
    const res = await fetch(`${BaseURL}/posts`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
    });
    const data = await res.json();

    if (data.success) {
      if (endPath) {
        setAllPosts(data.data);
        setPostLoader(false);

        const myAllPosts = await data.data.flatMap(
          (category) => category.posts
        );
        const filtered = await myAllPosts.filter((post) =>
          post.slug.includes(`${endPath}`)
        );

        setBodyData(filtered[0].body);
        setSelectedHighlight(filtered[0].slug);
      } else {
        setAllPosts(data.data);
        setPostLoader(false);
        setBodyData(data.data[0].posts[0].body);
        setSelectedHighlight(data.data[0].posts[0].slug);
      }
    } else {
      setAllPosts([]);
      // toast.error("Fetching failed");
      setPostLoader(false);
    }
  };

  useEffect(() => {
    // if(AccessToken){
    getAllPostFunc();
    // }
  }, [AccessToken]);
  // console.log("allPosts", allPosts);

  // useEffect(() => {
  //   const myAllPosts = allPosts.flatMap(user => user.posts);
  //   const filtered = myAllPosts.filter(post => post.slug.includes(`${endPath}`));

  //   console.log("filterData",filtered);

  //   // setBodyData(filtered[0]?.body)
  //   // setSelectedHighlight(filtered[0]?.slug)

  // }, [allPosts?.length>0 && endPath]);

  // ctrl + k Keyboard =======
  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === "k") {
      event.preventDefault();
      setIsSearchOpen((prevVisible) => !prevVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  if (postLoader) {
    return (
      <div className="flex items-center justify-center h-screen">Loading..</div>
    );
  }

  return (
    // <div className="max-w-[1440px] mx-auto relative">
    <div className="relative">
      <GlobalStyle darkTheme={darkTheme} />

      <div className="max-w-[1440px] mx-auto ">
        <Router>
          {/* <Layout /> */}
          <Routes>
            {/* {AccessToken ? ( */}
              {/* // <Route path="/" element={<Layout />}>
              //  <Route index element={<Home />} />
              // </Route> */}
              <Route path="/" element={<Layout />}>
                <Route path="/*" element={<Home />} />
              </Route>
            {/* ) : (
              // <Route path={`/*`} element={<Home />} />
              <>
                <Route path="/" element={<Login />} />
                <Route path="/callback" element={<Callback />} />
                <Route
                  path="/protected"
                  element={
                    <ProtectedRoute>
                      <ProtectedPage />
                    </ProtectedRoute>
                  }
                />
              </>
            )} */}

            {/* <Route path="/" element={<Login />} />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/protected"
              element={
                <ProtectedRoute>
                  <ProtectedPage />
                </ProtectedRoute>
              }
            /> */}

            {/* <Route path="/*" element={<PageNotFound />} /> */}
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
