import React, { useContext, useEffect, useRef, useState } from "react";
import LeftHome from "./slices/LeftHome";
import MiddleHome from "./slices/MiddleHome";
import RightHome from "./slices/RightHome";
import TopMenuHeader from "./slices/TopMenuHeader";
import { UserContext } from "../UserContext";
import Search from "./slices/Search";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const {
    isSmMenuOpen,
    setIsSmMenuOpen,
    isSearchOpen,
    setIsSearchOpen,
    darkTheme,
    bodyData,
    setBodyData,
    selectedHighlight,
    setSelectedHighlight,
  } = useContext(UserContext);
  const [openMenu, setOpenMenu] = useState(null);
  const [subOpenMenu, setSubOpenMenu] = useState(null);
  const [searchInp, setSearchInp] = useState("");
  // const [bodyData, setBodyData] = useState({});
  const [pageHeadings, setPageHeadings] = useState([]);
  // const [selectedHighlight, setSelectedHighlight] = useState(null)
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedHighlight) {
      navigate(`/${selectedHighlight}`, { replace: true });
    }
  }, [selectedHighlight]);

  const toggleDropdown = (index, getData) => {
    setElementsContent([]);

    setOpenMenu(openMenu === index ? null : index);
    setBodyData(openMenu === index ? {} : getData);
    setIsSmMenuOpen(getData?.items?.length > 0 ? true : false);
    setSelectedHighlight(null);
  };

  const subToggleDropdown = (title, getData, index) => {
    if (getData?.slug !== selectedHighlight) {
      setElementsContent([]);
      setSelectedHighlight(null);

      setSelectedHighlight(getData.slug);

      setBodyData(getData);
      setPageHeadings(getData);
      setIsSmMenuOpen(false);
      setIsSearchOpen(false);
      setSearchInp("");
    }
  };

  const SearchToggle = () => {
    setIsSearchOpen(false);
    setSearchInp("");
  };

  // middle page scroll to top
  // const scrollToTop = () => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTo({ top: 0, behavior: 'smooth' }); // Set scroll position to the top
  //   }
  // };

  // RIGHT PAGE SET-UP ========KPK
  const containerRef = useRef(null);
  const [activeElement, setActiveElement] = useState(null);
  const [elementsContent, setElementsContent] = useState([]);

  useEffect(() => {
    if (containerRef.current) {
      const elements = containerRef.current.querySelectorAll(
        "h1, h2, h3, h4, h5, h6, b"
      );
      // Store allTags for creating navigation links
      setElementsContent(Array.from(elements));

      // Observer to track when elements come into view
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              highlightElement(entry.target);
            }
          });
        },
        { rootMargin: "0px 0px -60% 0px", threshold: 0.1 } // Adjust rootMargin to trigger midway
      );

      // Add click event listener to each element
      elements.forEach((element, index) => {
        element.setAttribute("id", `tag-${index}`); // Assign unique ID to each tag
        observer.observe(element); // Observe each tag

        element.addEventListener("click", (e) => {
          e.preventDefault();

          // Scroll the clicked element into view
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            // block: "start",
            inline: "start",
          });
          // Highlight the clicked element
          highlightElement(element);
        });
      });

      // Cleanup event listeners on component unmount
      return () => {
        elements.forEach((element) => observer.unobserve(element));
      };
    }
  }, [bodyData, activeElement]);

  useEffect(() => {
    if (containerRef.current) {
      const elements = containerRef.current.querySelectorAll(
        "h1, h2, h3, h4, h5, h6, b"
      );
      elements.forEach((element) => {
        element.style.color = `${darkTheme ? "white" : "black"}`;
      });
      const pElem = containerRef.current.querySelectorAll("p");
      pElem.forEach((para) => {
        para.style.color = `${darkTheme ? "#bbb" : "#3a3a3a"}`;
      });
    }
  }, [darkTheme, bodyData]);

  const highlightElement = (element) => {
    setActiveElement(element.getAttribute("id"));
  };
  // RIGHT PAGE SET-UP ========KPK-END

  return (
    <div>
      {/* TOP MENU FOR small-devices  */}
      {isSmMenuOpen && (
        <div
          className={`HeightHome block md:hidden absolute z-30 top-14 left-0 right-0 ${
            darkTheme ? "bg-LightBlack" : "bg-White"
          }`}
        >
          <TopMenuHeader
            openMenu={openMenu}
            toggleDropdown={toggleDropdown}
            subToggleDropdown={subToggleDropdown}
          />
        </div>
      )}
      {isSearchOpen && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur"
          onClick={() => SearchToggle()}
        >
          <Search
            searchInp={searchInp}
            setSearchInp={setSearchInp}
            subToggleDropdown={subToggleDropdown}
          />
        </div>
      )}
      <div
        className={`HeightHome pt-8 px-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2`}
      >
        <div className="scrollBar-container">
          <LeftHome
            openMenu={openMenu}
            toggleDropdown={toggleDropdown}
            subToggleDropdown={subToggleDropdown}
            selectedHighlight={selectedHighlight}
          />
        </div>
        <div className="scrollBar-container md:col-span-2">
          <MiddleHome loadBodyData={bodyData} containerRef={containerRef} />
        </div>

        <div className="scrollBar-container">
          <RightHome
            elementsContent={elementsContent}
            highlightElement={highlightElement}
            activeElement={activeElement}
          />
        </div>
      </div>
    </div>
  );
}
