import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../UserContext";
import DOMPurify from "dompurify";
import { motion } from "framer-motion";

export default function MiddleHome({ loadBodyData, containerRef }) {
  const { darkTheme, bodyData } = useContext(UserContext);
  // const sanitizedHTML = DOMPurify.sanitize(bodyData.body);
  const sanitizedHTML = DOMPurify.sanitize(
    loadBodyData.body ? loadBodyData.body : bodyData
  );

  // const containerRef = useRef(null);
  // const [elementsContent, setElementsContent] = useState([]);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     // Select all <h1>, <h2>, <h3>, and <b> elements
  //     const elements = containerRef.current.querySelectorAll('h1, h2, h3, b');

  //      // Add click event listener to each element
  //      elements.forEach((element) => {
  //       element.addEventListener('click', (e) => {
  //         e.preventDefault();
  //         // Append the clicked element's text content to the state array
  //         setClickedTexts((prevTexts) => [...prevTexts, element.textContent]);

  //         // Scroll the clicked element into view
  //         element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //       });
  //     });

  //     // Cleanup event listeners on component unmount
  //     return () => {
  //       elements.forEach((element) => {
  //         element.removeEventListener('click', () => {});
  //       });
  //     };
  //   }
  // }, []);

  return (
    <div
      id="middle-content"
      className={`scrollBar HeightMiddleHome rounded-xl p-3 md:p-4 overflow-auto ${
        darkTheme ? "bg-[#637474]" : "bg-Bg"
      }`}
    >
      <motion.div
        className={`prose ${darkTheme ? "prose-moon" : "prose-sun"}`}
        key={loadBodyData.body ? loadBodyData.slug : bodyData}
        initial={{opacity: 0, y:25}}
        animate={{opacity:1, y:0}}
        exit={{opacity: 1, y: 25}}
        transition={{delay: 0.30, type: "easeInOut", duration:0.7}}

        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />
      {/* <div className="bg-Red p-2 rounded-full absolute bottom-4">
        <button onClick={()=> scrollToTop()}>iu</button>
      </div> */}
    </div>
  );
}
