import React, { useContext } from "react";
import { UserContext } from "../UserContext";
import { motion } from "framer-motion";
import { SunIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { AiFillMoon } from "react-icons/ai";
import { HiMenu } from "react-icons/hi";
import { FaXmark } from "react-icons/fa6";
import Input from "../reusable/Input";

export default function Header() {
  const {
    darkTheme,
    setDarkTheme,
    setIsSmMenuOpen,
    isSmMenuOpen,
    isSearchOpen,
    setIsSearchOpen,
  } = useContext(UserContext);
  const ToggleThemeFunc = () => {
    setDarkTheme(!darkTheme);
  };
  const headerMenuToggle = () => {
    setIsSmMenuOpen(!isSmMenuOpen);
  };
  return (
    // <div className={`bg-Black max-w-[1680px] mx-auto`}>
      <div className={`max-w-[1440px] mx-auto`}>
        <div
          className={`h-14 px-4 rounded sticky top-0 z-40 backdrop-blur-md flex items-center justify-between bg-Black`}
        >
          {/* ${darkTheme ? "bg-Black" : "bg-Border"} */}
          <div className={`flex items-center gap-3`}>
            <div
              onClick={() => headerMenuToggle()}
              className={`block md:hidden`}
            >
              {isSmMenuOpen ? (
                <FaXmark className={`w-5 h-5 text-White`} />
              ) : (
                // ${darkTheme ? "text-White" : "text-Black"}
                <HiMenu className={`w-5 h-5 text-White`} />
              )}
            </div>
            {/* {darkTheme ? ( */}
            <img
              src="./assets/logo-white.webp"
              className={`w-32 md:w-44`}
              alt="logo"
            />
            {/* ) : (
          <img src="./assets/logo-blue-black.webp" className={`w-32 md:w-44`} alt="logo" />
        )} */}
          </div>

          <div className="flex items-center gap-2">
            <a
              href="https://cfl.cloudframe.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="hidden md:block px-3 py-1 text-White"
            >
              CloudFrame
            </a>
            {/* SEARCH ======== */}
            <div
              onClick={() => setIsSearchOpen(!isSearchOpen)}
              className={`relative flex items-center rounded-xl bg-DarkGray`}
              // ${darkTheme ? "bg-DarkGray" : "bg-White" }
            >
              <div className={`p-2 cursor-pointer`}>
                <MagnifyingGlassIcon className={`w-5 h-5 text-[#babec5]`} />
                {/* ${darkTheme? "text-[#babec5]" : "text-Icon"} */}
              </div>
              <Input
                placeholder="Search"
                type="text"
                className={`!px-0 !py-1.5 hidden md:block !bg-DarkGray`}
                readOnly
              />
              <div
                className={`absolute right-2 hidden md:block text-[13px] text-[#babec5]`}
              >
                {/* ${darkTheme? "text-[#babec5]" : "text-Icon"} */}
                Ctrl + K
              </div>
            </div>
            <motion.button
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1 }}
              onClick={() => ToggleThemeFunc()}
              className={`!p-2 rounded-full ${
                darkTheme ? "!bg-Orange" : "!bg-Violet"
              }`}
            >
              <motion.div
                initial={{ opacity: 0, rotate: -90 }}
                animate={{ opacity: 1, rotate: 0 }}
                exit={{ opacity: 0, rotate: 90 }}
                transition={{ duration: 0.2 }}
              >
                {darkTheme ? (
                  <SunIcon className={`w-5 h-5 !text-Black`} />
                ) : (
                  <AiFillMoon className={`w-5 h-5 !text-White`} />
                )}
              </motion.div>
            </motion.button>
          </div>
        </div>
      </div>
    // </div>
  );
}
