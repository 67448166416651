import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import Input from "../../reusable/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { SampleDataTesting } from "../../SampleDataTesting";
import { motion } from "framer-motion";

export default function Search({ searchInp, setSearchInp, subToggleDropdown }) {
  const { darkTheme, allPosts } = useContext(UserContext);
  // const [loading, setLoading] = useState(false);

  // Search DATA
  // const filteredSearchData = allPosts.filter((item) => {
  //   const searchValue = searchInp.toLowerCase();
  //   const name = item.title.toLowerCase() || item.subTitle.toLowerCase();
  //   console.log("name", name);
  //   return name && name.startsWith(searchValue);
  // });

  // const filteredSearchData = allPosts.filter((item) => {
  //   const itemPosts = item?.posts?.filter((post, postIndex)=>{
  //     const searchValue = searchInp.toLowerCase();
  //     const name = post.title.toLowerCase();
  //     console.log("name", name);
  //   return name && name.startsWith(searchValue);
  //   })
  //   return itemPosts;
  // });

  const filteredSearchData = allPosts.filter((parent) => {
    const nameMatches = parent.name
      .toLowerCase()
      .startsWith(searchInp.toLowerCase());
    const titleMatches = parent.posts.some((item) =>
      item.title.toLowerCase().startsWith(searchInp.toLowerCase())
    );
    return nameMatches || titleMatches;
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
      className={`relative mx-4 mt-11 md:mt-20 md:mx-auto md:w-[680px] lg:w-[850px] rounded-xl`}
      onClick={(e) => e.stopPropagation()}
    >
      {/* <Button onClick={() => setIsSearchOpen(false)}>X</Button> */}

      <div className={`sticky top-0`}>
        <div
          className={`flex items-center gap-1 pl-2 md:pl-3 rounded-xl ${
            darkTheme ? "bg-DarkGray" : "bg-White"
          }`}
        >
          <MagnifyingGlassIcon
            className={`w-5 h-5 ${darkTheme ? "text-[#babec5]" : "text-Icon"}`}
          />
          <div className="flex-grow">
            <Input
              type="text"
              placeholder="Search Content"
              className={`w-full !py-2 !pl-0`}
              value={searchInp}
              onChange={(e) => setSearchInp(e.target.value)}
              autoFocus
            />
          </div>
        </div>
      </div>

      {/* SEARCH SCROLLING / CONTENT  */}
      <div
        className={
          "rounded-lg max-h-[500px] md:max-h-[660px] overflow-y-auto overflow-x-auto mt-2 scrollBar " +
          `${searchInp === "" ? "hidden" : "block"} || ${
            darkTheme ? "bg-Icon" : "bg-White"
          }`
        }
      >
        <>
          {/* {!loading ? ( */}
          <>
            {
              // SampleDataTesting.filter((item) => {
              //   const searchValue = searchInp.toLowerCase();
              //   const name =
              //     item.title.toLowerCase() || item.subTitle.toLowerCase();
              //   return name && name.startsWith(searchValue);
              // })
              filteredSearchData.length > 0 ? (
                filteredSearchData.map((item, index) => (
                  <>
                    {item?.posts?.length > 0 ? (
                      item.posts.map((post, postIndex) => (
                        <div
                          key={postIndex}
                          className={
                            "cursor-pointer " +
                            `${searchInp === "" ? "hidden" : "block"} ||
                      ${darkTheme ? "bg-DarkGray" : "bg-White"}`
                          }
                          // onClick={() => mergeDataArrFun(item)}
                        >
                          <div
                            onClick={() =>
                              subToggleDropdown(post.title, post, postIndex)
                            }
                            className={`flex gap-2 flex-grow flex-wrap border-b px-3 py-3 md:px-4 ${
                              darkTheme
                                ? "border-b-LightBlack hover:bg-LightBlack"
                                : "border-b-Bg hover:bg-Bg"
                            }`}
                          >
                            {/* Content  */}
                            {post.title}
                          </div>
                          <div>{/* if you Edit /delet btn impl. here  */}</div>
                        </div>
                      ))
                    ) : (
                      <span
                        className={
                          "py-2 px-3 " +
                          `${searchInp === "" ? "hidden" : "block"} ||
                    ${
                      darkTheme
                        ? "bg-DarkGray text-Orange"
                        : "bg-White text-Violet"
                    }`
                        }
                      >
                        Not Matched
                      </span>
                    )}
                  </>
                ))
              ) : (
                <span
                  className={
                    "py-2 px-3 " +
                    `${searchInp === "" ? "hidden" : "block"} ||
                  ${
                    darkTheme
                      ? "bg-DarkGray text-Orange"
                      : "bg-White text-Violet"
                  }`
                  }
                >
                  Not Matched
                </span>
              )
            }
          </>
          {/* ) : (
             <span className="px-3">Loading..</span>
           )} */}
        </>
      </div>
    </motion.div>
  );
}
