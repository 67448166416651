import React, { useContext, useEffect } from 'react'
import Header from '../pages/Header'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from '../UserContext'
import { AccessToken } from './AccessToken'

export default function Layout() {
  const {selectedHighlight} = useContext(UserContext)
  const navigate = useNavigate()
  // const location = useLocation()
  useEffect(()=>{
    if(selectedHighlight){
      navigate(`/${selectedHighlight}`, { replace: true })
    }
  },[AccessToken])

  // Remove leading slash if it exists
  // const pathWithoutLeadingSlash = location.pathname.replace(/^\/+/, '');
  // console.log("loca", pathWithoutLeadingSlash);
  return (
    <div>
        <Header />
        <Outlet />
    </div>
  )
}
