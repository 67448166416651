import { createContext, useState } from "react";

const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const [darkTheme, setDarkTheme] = useState(false);
  const [isSmMenuOpen, setIsSmMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  // const [resToken, setResToken] = useState({});
  const [resToken, setResToken] = useState({});
  const [allPosts, setAllPosts] = useState([]);
  const [bodyData, setBodyData] = useState({});
  const [selectedHighlight, setSelectedHighlight] = useState(null)
  return (
    <UserContext.Provider
      value={{
        darkTheme,
        setDarkTheme,
        isSmMenuOpen,
        setIsSmMenuOpen,
        isSearchOpen,
        setIsSearchOpen,
        resToken, setResToken,
        allPosts, setAllPosts,
        bodyData, setBodyData,
        selectedHighlight, setSelectedHighlight,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
