import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import LeftHome from "./LeftHome";
export default function TopMenuHeader({openMenu, toggleDropdown, subToggleDropdown}) {
  return (
    <div className={``}>
     <LeftHome openMenu={openMenu} open={true} toggleDropdown={toggleDropdown} subToggleDropdown={subToggleDropdown} />
    </div>
  );
}
