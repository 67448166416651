export const SampleDataTesting = [
  {
    title: "Your title is here",
    subTitle: "SubTitle is here",
    body: "<h2>Your subtitle is here</h2> <p>Companies focused on reducing mainframe costs can save significantly with CloudFrame Relocate.   Mainframe processing costs are significant. CloudFrame Relocate lets companies realize the cost benefit of Azure Cloud computing without having to endure a long, costly application migration project. Companies can typically see savings within just a few months.</p> <b>Shift COBOL Applications Processing to Cloud Without the Risk</b> <p> Enables seamless Hybrid Cloud deployments without the risk of changing data or application integration. Ensures 100% functional and data equivalency.</p> <h2>Leverage Cost Savings to Fund Future Modernization Initiatives</h2> <p>Automated Batch COBOL workload relocation to Cloud while maintaining backward compatibility to mainframe data, integration and operational continuity. Mainframe developers continue to maintain COBOL while performance optimized Java seamlessly runs on the Cloud.</p> <ul><li>COBOL to Java Cross-Compile - Fully automated process integrated into existing Source Management processes.</li> <li>Backward Compatibility - allows programs running in Cloud to access mainframe data, COBOL Load Modules, 3rd party programs and schedulers without requiring change to data or infrastructure.</li> <li>100% Functional and Numeric Equivalency - Ensure your business data is processed exactly as its COBOL predecessor.</li></ul>",
    slug: "create-cfl-page-view-4354ddw799",
    items: [
      {
        title: "Server Components",
        body: "<h2>Your subtitle is here</h2>",
        pageHeadings:[
          "Rendom",
          "Dynamic data",
          "Static data",
          "Our Java base core",
          "Some important notes",
        ],
        slug: "create-cfl-page-view-4354ddw799",
      },
      {
        title: "Client Components",
        body: "<h2>Your subtitle is here</h2> <p>Companies focused on reducing mainframe costs can save significantly with CloudFrame Relocate.   Mainframe processing costs are significant. CloudFrame Relocate lets companies realize the cost benefit of Azure Cloud computing without having to endure a long, costly application migration project. Companies can typically see savings within just a few months.</p> <b>Shift COBOL Applications Processing to Cloud Without the Risk</b> <p> Enables seamless Hybrid Cloud deployments without the risk of changing data or application integration. Ensures 100% functional and data equivalency.</p> <h2>Leverage Cost Savings to Fund Future Modernization Initiatives</h2> <p>Automated Batch COBOL workload relocation to Cloud while maintaining backward compatibility to mainframe data, integration and operational continuity. Mainframe developers continue to maintain COBOL while performance optimized Java seamlessly runs on the Cloud.</p> <ul><li>COBOL to Java Cross-Compile - Fully automated process integrated into existing Source Management processes.</li> <li>Backward Compatibility - allows programs running in Cloud to access mainframe data, COBOL Load Modules, 3rd party programs and schedulers without requiring change to data or infrastructure.</li> <li>100% Functional and Numeric Equivalency - Ensure your business data is processed exactly as its COBOL predecessor.</li></ul>",
        pageHeadings:[
          "Your subtitle is here",
          "Shift COBOL Applications Processing to Cloud Without the Risk",
          "Static data",
          "Our Java base core",
        ],
        slug: "create-cfl-page-view-4354ddw799",
      },
      {
        title: "Composition Patterns",
        body: "<h2>Your subtitle is here</h2> <p>Companies focused on reducing mainframe costs can save significantly with CloudFrame Relocate.   Mainframe processing costs are significant. CloudFrame Relocate lets companies realize the cost benefit of Azure Cloud computing without having to endure a long, costly application migration project. Companies can typically see savings within just a few months.</p> <b>Shift COBOL Applications Processing to Cloud Without the Risk</b> <p> Enables seamless Hybrid Cloud deployments without the risk of changing data or application integration. Ensures 100% functional and data equivalency.</p> <h2>Leverage Cost Savings to Fund Future Modernization Initiatives</h2> <p>Automated Batch COBOL workload relocation to Cloud while maintaining backward compatibility to mainframe data, integration and operational continuity. Mainframe developers continue to maintain COBOL while performance optimized Java seamlessly runs on the Cloud.</p> <ul><li>COBOL to Java Cross-Compile - Fully automated process integrated into existing Source Management processes.</li> <li>Backward Compatibility - allows programs running in Cloud to access mainframe data, COBOL Load Modules, 3rd party programs and schedulers without requiring change to data or infrastructure.</li> <li>100% Functional and Numeric Equivalency - Ensure your business data is processed exactly as its COBOL predecessor.</li></ul>",
        pageHeadings:[
          "Static data",
          "Our Java base core",
          "Some important notes",
        ],
        slug: "create-cfl-page-view-4354ddw799",
      },
      {
        title: "Partial Prerendering",
        body: "<h2>Your subtitle is here</h2> <p>Companies focused on reducing mainframe costs can save significantly with CloudFrame Relocate.   Mainframe processing costs are significant. CloudFrame Relocate lets companies realize the cost benefit of Azure Cloud computing without having to endure a long, costly application migration project. Companies can typically see savings within just a few months.</p> <b>Shift COBOL Applications Processing to Cloud Without the Risk</b> <p> Enables seamless Hybrid Cloud deployments without the risk of changing data or application integration. Ensures 100% functional and data equivalency.</p> <h2>Leverage Cost Savings to Fund Future Modernization Initiatives</h2> <p>Automated Batch COBOL workload relocation to Cloud while maintaining backward compatibility to mainframe data, integration and operational continuity. Mainframe developers continue to maintain COBOL while performance optimized Java seamlessly runs on the Cloud.</p> <ul><li>COBOL to Java Cross-Compile - Fully automated process integrated into existing Source Management processes.</li> <li>Backward Compatibility - allows programs running in Cloud to access mainframe data, COBOL Load Modules, 3rd party programs and schedulers without requiring change to data or infrastructure.</li> <li>100% Functional and Numeric Equivalency - Ensure your business data is processed exactly as its COBOL predecessor.</li></ul>",
        pageHeadings:[
          "Your subtitle is here",
          "Shift COBOL Applications Processing to Cloud Without the Risk",
          "Our Java base core",
          "Some important notes",
        ],
        slug: "create-cfl-page-view-4354ddw799",
      },
      {
        title: "Runtimes",
        body: "<h2>Your subtitle is here</h2> <p>Companies focused on reducing mainframe costs can save significantly with CloudFrame Relocate.   Mainframe processing costs are significant. CloudFrame Relocate lets companies realize the cost benefit of Azure Cloud computing without having to endure a long, costly application migration project. Companies can typically see savings within just a few months.</p> <b>Shift COBOL Applications Processing to Cloud Without the Risk</b> <p> Enables seamless Hybrid Cloud deployments without the risk of changing data or application integration. Ensures 100% functional and data equivalency.</p> <h2>Leverage Cost Savings to Fund Future Modernization Initiatives</h2> <p>Automated Batch COBOL workload relocation to Cloud while maintaining backward compatibility to mainframe data, integration and operational continuity. Mainframe developers continue to maintain COBOL while performance optimized Java seamlessly runs on the Cloud.</p> <ul><li>COBOL to Java Cross-Compile - Fully automated process integrated into existing Source Management processes.</li> <li>Backward Compatibility - allows programs running in Cloud to access mainframe data, COBOL Load Modules, 3rd party programs and schedulers without requiring change to data or infrastructure.</li> <li>100% Functional and Numeric Equivalency - Ensure your business data is processed exactly as its COBOL predecessor.</li></ul>",
        pageHeadings:[
          "Your subtitle is here",
          "Shift COBOL Applications Processing to Cloud Without the Risk",
          "Static data",
          "Our Java base core",
          "Some important notes",
        ],
        slug: "create-cfl-page-view-4354ddw799",
      },
    ],
  },
  {
    title: "title is here",
    subTitle: "bubTitle is here",

    body: "<h2>Your subtitle is here</h2> <p>Companies focused on reducing mainframe costs can save significantly with CloudFrame Relocate.   Mainframe processing costs are significant. CloudFrame Relocate lets companies realize the cost benefit of Azure Cloud computing without having to endure a long, costly application migration project. Companies can typically see savings within just a few months.</p> <b>Shift COBOL Applications Processing to Cloud Without the Risk</b> <p> Enables seamless Hybrid Cloud deployments without the risk of changing data or application integration. Ensures 100% functional and data equivalency.</p> <h2>Leverage Cost Savings to Fund Future Modernization Initiatives</h2> <p>Automated Batch COBOL workload relocation to Cloud while maintaining backward compatibility to mainframe data, integration and operational continuity. Mainframe developers continue to maintain COBOL while performance optimized Java seamlessly runs on the Cloud.</p> <ul><li>COBOL to Java Cross-Compile - Fully automated process integrated into existing Source Management processes.</li> <li>Backward Compatibility - allows programs running in Cloud to access mainframe data, COBOL Load Modules, 3rd party programs and schedulers without requiring change to data or infrastructure.</li> <li>100% Functional and Numeric Equivalency - Ensure your business data is processed exactly as its COBOL predecessor.</li></ul>",
    slug: "create-cfl-page-view-4354ddw799",
    items: [],
  },
  {
    title: "Your title is here",
    subTitle: "aubTitle is here",

    body: "<h2>Your subtitle is here</h2> <p>Companies focused on reducing mainframe costs can save significantly with CloudFrame Relocate.   Mainframe processing costs are significant. CloudFrame Relocate lets companies realize the cost benefit of Azure Cloud computing without having to endure a long, costly application migration project. Companies can typically see savings within just a few months.</p> <b>Shift COBOL Applications Processing to Cloud Without the Risk</b> <p> Enables seamless Hybrid Cloud deployments without the risk of changing data or application integration. Ensures 100% functional and data equivalency.</p> <h2>Leverage Cost Savings to Fund Future Modernization Initiatives</h2> <p>Automated Batch COBOL workload relocation to Cloud while maintaining backward compatibility to mainframe data, integration and operational continuity. Mainframe developers continue to maintain COBOL while performance optimized Java seamlessly runs on the Cloud.</p> <ul><li>COBOL to Java Cross-Compile - Fully automated process integrated into existing Source Management processes.</li> <li>Backward Compatibility - allows programs running in Cloud to access mainframe data, COBOL Load Modules, 3rd party programs and schedulers without requiring change to data or infrastructure.</li> <li>100% Functional and Numeric Equivalency - Ensure your business data is processed exactly as its COBOL predecessor.</li></ul>",
    slug: "create-cfl-page-view-4354ddw799",
    items: [{title:"Composition Patterns"}, {title: "Partial Prerendering"}, {title:"Runtimes"}],
  },
];
