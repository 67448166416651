import React, { useContext, useState } from "react";
import { UserContext } from "../../UserContext";
import { SampleDataTesting } from "../../SampleDataTesting";
import { AnimatePresence, motion } from "framer-motion";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

export default function LeftHome({
  openMenu,
  toggleDropdown,
  subToggleDropdown,
  open,
  selectedHighlight,
  
}) {
  const { darkTheme, allPosts } = useContext(UserContext);

  //   ANIMATION
  const dropdownVariants = {
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        // ease: "easeInOut",
        ease: [0.42, 0, 0.58, 1],
      },
    },
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.5,
        // ease: "easeInOut",
        ease: [0.42, 0, 0.58, 1],
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    closed: {
      opacity: 0,
      y: -20,
    },
    open: { opacity: 1, y: 0 },
  };

  return (
    <div
      className={`scrollBar ${
        open ? "block p-2 mx-4" : "hidden"
      } md:block overflow-auto rounded-xl`}
      // ${
      //   darkTheme ? "bg-LightBlack" : "bg-Border"
      // }
    >
      {/* DYNAMIC DROPDOWN MENU  */}
      <div className="">
        {allPosts.map((menu, index) => (
          <div key={index} className="">
            <div
              // onClick={() => toggleDropdown(index, menu)}
              className={`line-clamp-1 py-1 px-3 rounded-xl w-full text-left font-medium font-Barlow_medium flex items-center justify-between`}
            >
              {menu.name}
              {/* {
                menu?.posts?.length > 0 && (
                  <MdKeyboardArrowRight
                    className={`w-5 h-5 ${
                      openMenu === index &&
                      menu?.posts?.length > 0 &&
                      "rotate-90 transition-all duration-500"
                    }`}
                  />
                )
              } */}
            </div>
            <AnimatePresence>
              {/* {openMenu === index && menu.posts.length > 0 && ( */}
              {menu.posts.length > 0 && (
                <motion.ul
                  initial="closed"
                  animate="open"
                  exit="closed" // Exit animation for closing
                  variants={dropdownVariants}
                  className={`pl-3 ml-3 border-l ${
                    darkTheme ? "border-l-DarkGray" : "border-l-Border"
                  }`}
                >
                  {menu.posts.map((item, itemIndex) => (
                    <motion.li
                      variants={itemVariants}
                      key={itemIndex}
                      className={`font-Barlow_medium active:bg-opacity-15 transition-all ${
                        darkTheme ? "text-[#bebebe] hover:bg-Orange hover:bg-opacity-50" : "text-[#6e6e6e] hover:bg-Violet hover:bg-opacity-30"
                      } line-clamp-1 py-1 px-3 rounded-xl cursor-pointer hover:text-White ${selectedHighlight===item.slug ? `${darkTheme? "bg-Orange bg-opacity-30" : "bg-Violet bg-opacity-10"}`: ""}`}
                      onClick={() => subToggleDropdown(item.title, item, itemIndex)}
                    >
                      {item.title}
                    </motion.li>
                  ))}
                </motion.ul>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
}
